var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c("header", { staticClass: "header header--lg" }, [
      _c("div", { staticClass: "container container--xs" }, [
        _c("div", { staticClass: "artist-type" }, [
          _vm.backgroundImagePath
            ? _c("img", {
                staticClass: "artist-type__img-background",
                attrs: { src: _vm.backgroundImagePath },
              })
            : _vm._e(),
          _c("div", { staticClass: "artist-type__wrapper" }, [
            _vm.artistType && _vm.artistType.image_url
              ? _c("img", {
                  staticClass: "artist-type__img-artist",
                  attrs: {
                    src: _vm.artistType.image_url,
                    alt: "Artist Type Image",
                  },
                })
              : _vm._e(),
            _c("div", { staticClass: "artist-type__info" }, [
              _c("h2", { staticClass: "h2--small-uppercase" }, [
                _vm._v("ARTIST TYPE"),
              ]),
              _c("h1", { staticClass: "artist-type__h1" }, [
                _vm._v(
                  _vm._s(_vm.artistType ? _vm.artistType.name : "loading…")
                ),
              ]),
            ]),
          ]),
          _vm.artistType && _vm.artistType.related_artist_types.length >= 1
            ? _c(
                "div",
                { staticClass: "flexbox--alignRight" },
                _vm._l(_vm.artistType.related_artist_types, function (artist) {
                  return _c(
                    "router-link",
                    {
                      key: artist.id,
                      staticClass: "related-artist-types",
                      attrs: {
                        to: {
                          name: "ArtistType",
                          params: { slug: artist.slug },
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "related-artist-types__name" }, [
                        _vm._v(_vm._s(artist.name)),
                      ]),
                      _c("img", {
                        staticClass: "related-artist-types__img-background",
                        attrs: { src: artist.image_url, alt: "" },
                      }),
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("BeatTable", {
            attrs: {
              parentState: _vm.ParentStateType.ArtistType,
              beatPlaylist: _vm.beats,
            },
            on: {
              fetchMoreData: function ($event) {
                return _vm.updateBeatsTable($event, _vm.beats.length)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }