
























import Vue from 'vue'
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator'

import { mapState } from 'vuex'
import BeatTable from '@/components/BeatTable.vue'
import { BeatStore } from '@/store/beat'
import { fetchBeats, BeatFilterType } from '~/api/beats'
import { fetchSingleArtistType } from '@/api/artist-type'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTable } })
export default class ArtistTypePage extends Mixins(InfiniteLoadingSetState) {
    @Prop({ type: String }) slug!: string

    ParentStateType = ParentStateType
    beats: IBeat[] = []
    artistType: IArtistType = null

    get backgroundImagePath() {
        if (!this.artistType) return null
        const path = './../img/artist-type-page-background/'
        switch (this.artistType.id % 9) {
            case 0:
                return `${path}bgTriangle1.svg`
            case 1:
                return `${path}bgTriangle2.svg`
            case 2:
                return `${path}bgTriangle3.svg`
            case 3:
                return `${path}bgCircle1.svg`
            case 4:
                return `${path}bgCircle2.svg`
            case 5:
                return `${path}bgCircle3.svg`
            case 6:
                return `${path}bgSquare1.svg`
            case 7:
                return `${path}bgSquare2.svg`
            case 8:
                return `${path}bgSquare3.svg`
            default:
                return `${path}bgTriangle1.svg`
        }
    }

    async fetchSingleArtistType() {
        this.artistType = await fetchSingleArtistType(this.slug)
    }

    // TODO: fix .push, replace if slug is replaced
    async updateBeatsTable($state: any, offset: number) {
        // call the store to update the beats
        const beats = await fetchBeats({ filterType: BeatFilterType.ArtistType, slug: this.slug, offset })
        this.beats.length > 0 ? this.beats.push(...beats) : (this.beats = beats)
        // set loading state for vue-infinite-loading
        this.setLoadingStateAndUpdatePlayerQueue($state, beats)
    }

    fetchEverything() {
        this.fetchSingleArtistType()
        this.updateBeatsTable(null, 0)
    }

    @Watch('slug')
    onSlugChange() {
        // reset beats list
        this.beats = []
        this.fetchEverything()
    }

    mounted() {
        this.fetchEverything()
    }
}
